import { EntityType } from '@/shared/enums';

const activitySummaryDimension = {
  id: `${EntityType.ACTIVITY}`,
  label: 'Activity',
  order: [EntityType.ACTIVITY],
};

const organisationDimensions = [
  {
    id: `${EntityType.ROLE}:${EntityType.ACTIVITY}:${EntityType.PERSON}:${EntityType.GROUP}`,
    label: 'Role > Activity > Person > Team',
    order: [
      EntityType.ROLE,
      EntityType.ACTIVITY,
      EntityType.PERSON,
      EntityType.GROUP,
    ],
  },
  {
    id: `${EntityType.ROLE}:${EntityType.ACTIVITY}:${EntityType.GROUP}:${EntityType.PERSON}`,
    label: 'Role > Activity > Team > Person',
    order: [
      EntityType.ROLE,
      EntityType.ACTIVITY,
      EntityType.GROUP,
      EntityType.PERSON,
    ],
  },
  {
    id: `${EntityType.GROUP}:${EntityType.ACTIVITY}:${EntityType.ROLE}:${EntityType.PERSON}`,
    label: 'Team > Activity > Role > Person',
    order: [
      EntityType.GROUP,
      EntityType.ACTIVITY,
      EntityType.ROLE,
      EntityType.PERSON,
    ],
  },
];

const roleDimensions = [
  {
    id: `${EntityType.ROLE}`,
    label: 'Role',
    order: [EntityType.ACTIVITY, EntityType.ROLE],
  },
];

const EntityActivityDimensions = {
  [EntityType.ACTIVITY]: activitySummaryDimension,
  [EntityType.GROUP]: organisationDimensions,
  [EntityType.MANAGER]: organisationDimensions,
  [EntityType.ORGANISATION]: organisationDimensions,
  [EntityType.ROLE]: roleDimensions,
};

export default EntityActivityDimensions;
