export default function formatTagsAsOptions(ordered, entityType) {
  const options = [];

  ordered.__order.forEach((type) => {
    const isSystem = type === 'system';

    ordered[type].__order.map((uuid) => {
      const category = ordered[type][uuid];
      const isPrimary = uuid === 'primary';
      const tags = [];

      category.tags.__order.forEach((uuid) => {
        const tag = category.tags[uuid];
        const isApplicable =
          category.tags[uuid]?.__taggables_set.has(entityType) ||
          uuid === 'CBfU6MtgGCNEboFwZ1RDN';
        const isEnabled = !tag.is_disabled;
        if (isApplicable && isEnabled) {
          const { color, name, description } = tag;
          tags.push({
            id: uuid,
            value: uuid,
            properties: {
              color,
              description,
              name,
            },
          });
        }
      });

      if (tags.length) {
        const name = isSystem && isPrimary ? 'Beamible' : category.name;
        const id = isSystem ? 'beamible' : uuid;
        options.push({
          id,
          name,
          categorise: !isPrimary,
          options: tags,
        });
      }
    });
  });

  return options;
}
